import React, { VoidFunctionComponent } from 'react'
import { GetStaticProps } from 'next'
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'

import { Homepage } from '@mindfulchefuk/features/Homepage/Homepage'
import { getCMSPage } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { getCMSGlobalContent } from '@mindfulchefuk/features/CMS/services/getCMSGlobalContent'
import { getDateDeliverabilities } from '@mindfulchefuk/features/Delivery/services/getDateDeliverabilities'
import { getImportantDates } from '@mindfulchefuk/features/Operations/utils/getImportantDates'
import { DateDeliverability } from '@mindfulchefuk/features/Delivery/interfaces'
import { useDispatch } from 'react-redux'
import { setDeliveryDate } from '@mindfulchefuk/actions/basketActions'
import { MC_POSTCODE } from '@mindfulchefuk/constants'

const Index: VoidFunctionComponent = () => {
  const dispatch = useDispatch()
  const { data }: { data: DateDeliverability[] } = useQuery([
    'dateDeliverabilities',
  ])

  const firstAvailableDeliveryDate = data?.find(
    (date: DateDeliverability) => date.recipeDeliverable
  )?.deliveryDate

  dispatch(setDeliveryDate(firstAvailableDeliveryDate))

  return <Homepage />
}

export default Index

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(['cms-page-homepage'], () =>
    getCMSPage('page-homepage')
  )
  await queryClient.prefetchQuery(['cms-global-content'], getCMSGlobalContent)

  const { tomorrow, maxPastDate } = getImportantDates()

  await queryClient.prefetchQuery(['dateDeliverabilities'], () =>
    getDateDeliverabilities({
      minDate: tomorrow,
      maxDate: maxPastDate,
      postcode: MC_POSTCODE,
    })
  )

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_CMS_REVALIDATE_INTERVAL, 10),
  }
}
